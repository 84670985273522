import React from "react";
import Navigation from "../components/Navigation";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import PopularMovies from "../components/PopularMovies";

const Popular = () => {

    return (
        <div className="popular">
            
            <Navigation />
            <Hero />
            <div className="container-fluid tm-container-content tm-mt-60">
            <div className="row mb-4">
            <h1 className="col-6 tm-text-primary ">
            Populaires sur Movix
            </h1>
            </div>
            </div>
            <PopularMovies />
           
            <Footer />
        </div>
    )
}
export default Popular;