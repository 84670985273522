import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SearchMovie from "../components/SearchMovie";
const Search = () => {

    return (
        <div>
            <Navigation />
            <SearchMovie />
            <Footer />
        </div>
    )
}
export default Search;