import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useHistory } from "react-router-dom";

const CategoriesMovies = (props) => {

  const { idGenre, nameGenre } = props;
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [id, setId] = useState();
  const idParam = idGenre ? idGenre : id;
  const URL = `https://api.themoviedb.org/3/discover/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&with_genres=${idParam}&page=${page}`;
  const URL_GENRES = "https://api.themoviedb.org/3/genre/movie/list?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US";


  const history = useHistory();

  useEffect(() => {
    axios.get(URL).then((res) => setData(res.data.results));
    axios.get(URL_GENRES).then((res) => setCategories(res.data.genres));
  }, [URL]);

  const onChange = (e) => {
    setId(e.target.value);
  }

  const nextPage = () => {
    if (page < 39450) setPage(page + 1); 
  }

  const prevPage = () => {
    if (page > 1) setPage(page - 1);
  }


  return (
    <div className="categoriesMovies">

      {!idGenre && (
        <select value={id} onChange={onChange} >
          <option>Choisissez une Catégorie</option>
          {categories.map(genre => {
            return <option value={genre.id}>{genre.name}</option>
          })}
        </select>
      )}
      {idParam ? (
       <React.Fragment>
          <div className="container-fluid tm-container-content tm-mt-60">
            <h1 className="titleCategory">{nameGenre}</h1>
            <div className="row tm-mb-90 tm-gallery">
              {data.map((m) => (
                <Movie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails', { id: m.id })} />
              ))}
            </div>
          </div>
          <div className="button-container1">
            <button className="prev-button" onClick={prevPage} disabled={page === 1}>Page précédente</button>
            <button className="next-button" onClick={nextPage}>Page suivante</button>
          </div>

          </React.Fragment>
      ) : (


        <div className="placeholder-container">
          <h2>Rien à afficher, veuillez sélectionner une catégorie</h2>
        </div>
      )}

    </div>
  )
}

export default CategoriesMovies;