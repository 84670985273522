import React from "react";

const Movie = (props) => {

    const { movie, onClickMovie } = props;

    return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
        <figure className="effect-ming tm-movix-item" onClick={onClickMovie}>
            <img 
            src={movie.poster_path ?
                 `http://image.tmdb.org/t/p/original${movie.poster_path}`
                  : "/images/poster.jpg"} 
            alt={movie.title}  
            className="img-fluid" />
            <figcaption className="d-flex align-items-center justify-content-center">
                <h2>{movie.title}</h2>
                </figcaption>
        </figure>
        </div>
    );
}

export default Movie;
