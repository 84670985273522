import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (

        <footer className="tm-bg-gray pt-5 pb-3 tm-text-gray tm-footer">


            <div className="row">
                <div className="col-lg-6 col-md-12 col-12 px-5 mb-5">
                    <h3 className="tm-text-primary mb-4 tm-footer-title">À propos de Movix</h3>
                    <p className="p-footer">Le site a été créé par une étudiante dynamique en développement web.
                        À travers des pages bien structurées et intuitives, ce site démontre sa maîtrise de l'esthétique et de la fonctionnalité,
                        équilibrant adroitement les éléments de conception et l'efficacité du code.</p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 px-5 mb-5">
                    <h3 className="tm-text-primary mb-4 tm-footer-title">Menu</h3>
                    <ul className="tm-footer-links pl-0">
                        <li><NavLink to="/">Popular</NavLink></li>
                        <li><NavLink to="/Categories">Categories</NavLink></li>
                        <li><NavLink to="/About">À propos</NavLink></li>

                    </ul>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 px-5 mb-5 social-m">
                    <ul className="tm-social-links d-flex justify-content-end pl-0 mb-5">
                        <li className="mb-2"><a href="https://facebook.com" target="_blank" rel="noreferrer"><img src="../images/facebook1.png" alt="Facebook" /></a></li>
                        <li className="mb-2"><a href="https://twitter.com" target="_blank" rel="noreferrer"><img src="../images/twitter1.png" alt="Twitter" /></a></li>
                        <li className="mb-2"><a href="https://instagram.com" target="_blank" rel="noreferrer"><img src="../images/instagram1.png" alt="Instagram" /></a></li>

                    </ul>
                </div>

            </div>
            <div className="row">

                <div className="col-lg-8 col-md-7 col-12 px-5 mb-3">
                    Copyright 2023 Movix. Tous droits réservés.
                </div>

            </div>

        </footer>

    )
}

export default Footer;