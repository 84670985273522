import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Popular from "./pages/Popular";
import Search from "./pages/Search";
import Categories from "./pages/Categories";
import About from "./pages/About";
import MovieDetails from "./pages/MovieDetails";
import ActorDetails from "./pages/ActorDetails";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <BrowserRouter>
    <Switch>
    <Route path="/" exact component={Popular} />
      <Route path="/search" exact component={Search} />
      <Route path="/categories" exact component={Categories} />
      <Route path="/about" exact component={About} />
      <Route path="/movieDetails" exact component={MovieDetails} />
      <Route path="/actorDetails" exact component={ActorDetails} />
      <Route exact component={NotFound} />
    </Switch>
  </BrowserRouter>
  )
}

export default App;