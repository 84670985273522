import React from "react";

const Actor = (props) => {

    const { actor, onClickActor } = props;

    return (
        <div className="actor" onClick={onClickActor}>
            <img src={
                actor.profile_path ?  
                `http://image.tmdb.org/t/p/original${actor.profile_path}` : 
                "/images/profile.png"
            } 
            alt="poster"/>
            <div className="actorContainer">
                <ul>
                    <li className="actorName">{actor.original_name}</li>
                </ul>
                
            </div>       
        </div>
    )
}

export default Actor;