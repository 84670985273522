import React from "react";

const NotFound = () => {
    return (
        <div className="notFound">
            <h1>Erreur 404, Page introuvable</h1>
            <img src="images/not-found.jpg" alt="not-found" className="img-fluid" />
        </div>
    )
}

export default NotFound;