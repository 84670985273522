import React from "react";
import { useHistory } from "react-router-dom";

const Hero = () => {
    const history = useHistory();

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const searchQuery = e.target.elements[0].value; 
        history.push(`/search?query=${searchQuery}`);  
    }

    return (
        <div className="tm-hero d-flex justify-content-center align-items-center" 
             style={{ backgroundImage: `url("movie.png")`, height: '200px' }}>
             
            <form className="d-flex tm-search-form" onSubmit={handleSearchSubmit}>
                <input 
                    className="form-control tm-search-input" 
                    type="search" 
                    placeholder="Recherche" 
                    aria-label="Search" 
                />
                
                <button className="btn btn-outline-success tm-search-btn" type="submit">
                    <i className="fas fa-search"></i>
                </button>
            </form>
        </div> 
    );
}

export default Hero;
