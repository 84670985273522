import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useHistory } from "react-router-dom";

const SearchMovie = () => {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const history = useHistory();

    useEffect(() => {
        if (query) {
            axios.get(`https://api.themoviedb.org/3/search/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&query=${query}&page=${page}`)
                 .then((res) => setData(res.data.results));
        }
    }, [query, page]);

    const onSearch = (event) => {
        setQuery(event.target.value);
        setPage(1); 
    }

    const nextPage = () => {
        if (page < 39450) setPage(page + 1); 
    }

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    }

    return (
        <div className="searchMovie">
            <div className="input-container">
                <input
                   className="input"
                   placeholder="Mot clé"
                   onChange={onSearch}
                ></input>
            </div>
            {query ? (
                <React.Fragment>
                    <div className="container-fluid tm-container-content tm-mt-60">
                        <div className="row tm-mb-90 tm-gallery">
                            {data.map((m) => (
                                <Movie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails', { id : m.id })} />
                            ))}
                        </div>   
                    </div>
                    <div className="button-container2">
            <button className="prev-button" onClick={prevPage} disabled={page === 1}>Page précédente</button>
            <button className="next-button" onClick={nextPage}>Page suivante</button>
          </div>
                </React.Fragment>
            ) : (
                <div className="placeholder-container">
                    <h2>Rien à afficher, veuillez entrer un mot clé</h2>
                </div>
            )} 
        </div>
    )
}

export default SearchMovie;
