import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

const About = () => {
    return (
        <div>
            <Navigation />
            <Hero />
            <div className="container-fluid tm-mt-90">

                <div className="row tm-mb-74 tm-row">
                    <div className="col-lg-5 col-md-12 col-12 mb-3 ">
                        <img src="images/about.jpg" alt="Affiche de MOVIX" className="img-fluid1" />
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 ">
                        <div className="tm-about-img-text ">
                            <h2 className="col-12 tm-text1-primary p-5  ">MOVIX</h2>
                            <p className="mb-4">
                                <b>Movix</b> est née de l'inspiration et de la vision d'une développeuse web passionnée, Sameh.
                                Dotée d'une solide maîtrise des techniques de programmation, des principes de conception d'interfaces
                                utilisateur et de l'architecture des bases de données,elle a décidé d'appliquer son expertise à sa passion pour le cinéma.
                            </p>
                            <p>
                                <b>Movix</b> est bien plus qu'une simple plateforme en ligne dédiée au cinéma.
                                C'est un pont entre la technologie et l'art, un espace où la passion pour le cinéma s'exprime librement.
                                Grâce à une profonde compréhension des attentes et des besoins des utilisateurs, Sameh a réussi à créer un site à la fois intuitif, esthétique et complet.
                                Movix propose non seulement des fiches détaillées pour chaque film, des critiques, des bandes-annonces et des actualités du cinéma, mais aussi un espace où
                                les cinéphiles peuvent s'immerger totalement, découvrir de nouveaux genres et réalisateurs, et partager leur passion avec d'autres.
                            </p>
                            <p>
                                <b>Sameh</b>  a non seulement réussi à créer un site intuitif, esthétique et complet, mais elle a également façonné une communauté vibrante.
                                Movix propose non seulement des fiches détaillées pour chaque film, des critiques, des bandes-annonces et des actualités du cinéma,
                                mais aussi un espace interactif où les cinéphiles peuvent s'immerger totalement, explorer de nouvelles perspectives, découvrir de nouveaux genres et
                                réalisateurs, et partager leur passion avec une communauté qui partage les mêmes idées
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;