import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useHistory } from "react-router-dom";

const PopularMovies = () => {
    const [popular, setPopular] = useState([]);
    const [num, setNum] = useState(1);
    const [isLoading, setIsLoading] = useState(true); 
    const history = useHistory();

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&page=${num}`)
            .then((res) => {
                setPopular(res.data.results);
                setIsLoading(false); 
            });
    }, [num]);

    const prevPage = () => {
        if (num > 1) setNum(num - 1);
    }

    const nextPage = () => {
        if (num < 39450) setNum(num + 1); 
    }

    if (isLoading) {
        return (
            <div className="container-fluid tm-container-content tm-mt-60">
                <div className="row tm-mb-90 tm-gallery">
                    <img src="/Hourglass.gif" alt="hourglass" /> 
                </div>
            </div>
        );
    }


    return (
        <div className="container-fluid tm-container-content tm-mt-60">
            <div className="row tm-mb-90 tm-gallery">
                {popular.map((p) => (
                    <Movie movie={p} key={p.id} onClickMovie={() => history.push('/movieDetails', { id: p.id })} />
                ))}
            </div>

            <div className="button-container">
                <button className="prev-button" onClick={prevPage} disabled={num === 1}>Page précédente</button>
                <button className="next-button" onClick={nextPage}>Page suivante</button>
            </div>

        </div>
    )
}

export default PopularMovies;
